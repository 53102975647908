import axios from 'axios';

const baseURL = "https://api.smartrotator.com";
// const baseURL = "http://localhost:8000/";

const app = axios.create({
  baseURL,
  withCredentials: true,
  withXSRFToken: true
})

app.interceptors.response.use(
  response => {
    if (response.status === 204) {
      return response;
    }

    return response;
  },
  error => {
    if (error.response && error.response.data && error.response.data.message) {
      const errorMessage = error.response.data.message;

      if ((errorMessage && errorMessage == "Invalid login credentials") || (errorMessage && errorMessage == "Access not granted.")) {
        error.response.data.message = "Login failed. Please check your email and password.";
      }

      if (errorMessage && (errorMessage == "CSRF token mismatch.")) {
        error.response.data.message = "You must login first to perform this action. You will be redirected to the login page in 5 seconds.";
        setTimeout(() => {
          window.location.href = "/login";
        }, 5000);

      }
    } else {
      error.response.data.message = 'Something went wrong. Please try again.';
    }

    return Promise.reject(error);
  }
)

export default app;
