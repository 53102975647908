import * as Ladda from 'ladda';
import 'ladda/dist/ladda-themeless.min.css';
import app from './axiosConfig';

export const formSubmitUtil = async (buttonRef, apiEndpoint = null, formData, modalRef = false, notif = 'toast') => {
    const laddaButton = Ladda.create(buttonRef);
    laddaButton.start();

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });

    try {
        let response;

        switch (typeof apiEndpoint) {
            case 'string': //contoh 'api/test'
                response = await app.post(apiEndpoint, formData);
                break;
            case 'object': //contoh {'method' : 'put', 'endpoint' : 'api/test'}
                const { method, endpoint } = apiEndpoint;
                switch (method) {
                    case 'put':
                        response = await app.put(endpoint, formData);
                        break;
                    case 'delete':
                        response = await app.delete(endpoint, { data: formData });
                        break;
                    case 'patch':
                        response = await app.patch(endpoint, formData);
                        break;
                    case 'get':
                        response = await app.get(endpoint);
                        break;
                    case 'post':
                        response = await app.post(endpoint, formData);
                        break;
                    default:
                        throw new Error('Invalid HTTP method');
                }
                break;
            default:
                throw new Error('Invalid Endpoint format');
        }

        const succeedMessage = response.data.message;

        if (notif === 'toast') {
            Toast.fire({
                icon: 'success',
                html: `
                <div style="text-align: left; margin-left: 10px">
                  <strong>Succeed</strong><br>
                  ${succeedMessage}
                </div>`,
            });
        } else if (notif === 'toastr') {
            toastr.success(succeedMessage, 'Success');
        }

        if (modalRef) {
            $('#' + modalRef).modal('hide');
        }

        setTimeout(() => {
            laddaButton.stop();
        }, 2000);

        return { success: true, data: response.data, status: response.status };
    } catch (error) {
        console.log(error);
        const errorMessage = error.response ? error.response.data.message : 'An unexpected error occurred';

        if (notif === 'toast') {
            Toast.fire({
                icon: 'error',
                html: `
                <div style="text-align: left; margin-left: 10px">
                  <strong>Failed</strong><br>
                  ${errorMessage}
                </div>`,
            });
        } else if (notif === 'toastr') {
            toastr.error(errorMessage, 'Error');
        }

        laddaButton.stop();

        return { success: false, data: error.response ? error.response.data : {}, status: error.response ? error.response.status : 500 };
    }
};
