<template>
    <footer class="main-footer">
        <strong>Copyright &copy; 2024 {{$store.state.app.name}}.</strong>
        All rights reserved.
        <!-- <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.2.0
        </div> -->
    </footer>
</template>


<script>
</script>   