<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">

        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto">
            <li v-if="showToolBar" class="nav-item">
                <a class="nav-link" data-widget="navbar-search" href="#" role="button">
                    <i class="fas fa-search"></i>
                </a>
                <div class="navbar-search-block">
                    <form class="form-inline" @submit.prevent="handleSearch">
                        <div class="input-group input-group-sm">
                            <input class="form-control form-control-navbar" type="search" placeholder="Search"
                                v-model="searchQuery" aria-label="Search">
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit">
                                    <i class="fas fa-search"></i>
                                </button>
                                <button class="btn btn-navbar" type="button" data-widget="navbar-search"
                                    @click.prevent"handleSearch">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </li>

            <template v-if="showToolBar && toolBar && toolBar.length > 0">
                <li class="nav-item dropdown">
                    <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                        <i class="fas fa-plus"></i>
                    </a>
                    <div class="dropdown-menu">
                        <template v-for="button in toolBar">
                            <!-- Jika bukan modal, gunakan router-link untuk navigasi antar komponen -->
                            <router-link v-if="button.show && !button.isModal" :to="button.link"
                                class="btn dropdown-item">
                                {{ button.text }}
                            </router-link>

                            <!-- Jika tombol untuk modal, gunakan button biasa dengan data-toggle dan data-target -->
                            <button v-else-if="button.show && button.isModal" type="button" class="btn dropdown-item"
                                data-toggle="modal" :data-target="`#${button.modal}`">
                                {{ button.text }}
                            </button>
                        </template>

                    </div>
                </li>
            </template>

            <template v-if="isCustomizeForm">
                <template v-for="item in navItems" :key="item.key">
                    <li class="nav-item">
                        <button class="nav-link" role="button" @click="item.action"
                            style="background-color:transparent; border:0;" :title="item.tooltip">
                            <i :class="item.icon"></i>
                        </button>
                    </li>
                </template>
            </template>

            <li v-if="showToolBar" class="nav-item">
                <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                    <i class="fas fa-filter"></i>
                </a>
            </li>

            <li v-if="showReload" class="nav-item">
                <a class="nav-link" href="#" role="button" @click.prevent"reloadPage">
                    <i class="fas fa-sync-alt"></i>
                </a>
            </li>

            <li class="nav-item" id="fullscreen-button">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>

            <!-- CAUTION : JANGAN HAPUS ELEMEN INI KARENA ELEMEN INI BISA DIUNCOMMENT KALAU MAU MANGGIL ControlSiderbar.vue -->


            <!-- <li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#"
                    role="button">
                    <i class="fas fa-th-large"></i>
                </a>
            </li> -->



            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                    <img src="/adminlte/dist/img/user2-160x160.jpg" alt="User Avatar" class="img-sm img-circle">
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click.prevent="logout">
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
import app from '../../utils/axiosConfig';
export default {
    data() {
        return {
            searchQuery: '',
        };
    },
    computed: {
        navItems() {
            return this.$store.state.formTools;
        },
        toolBar() {
            return this.$store.state.toolBar;
        },
        showToolBar() {
            const route = this.$route.path;
            if (route == '/rotator' || route == '/customer-service' || route == '/form') {
                return true;
            } else {
                return false;
            }
        },
        isCustomizeForm() {
            const route = this.$route.path;
            if (route.startsWith('/form-builder')) {
                return true;
            } else {
                return false;
            }
        },
        showReload() {
            const route = this.$route.path;
            if (route != '/form-builder') {
                return true;
            } else {
                return false;
            }
        },
    },
    props: {
    },
    mounted() {
    },
    methods: {
        handleSearch() {
            this.$store.dispatch('updateSearchQuery', this.searchQuery);
        },
        handleFilter() {
            console.log('filtered');
        },
        async logout() {
            try {
                await app.post('/logout', {}, {
                    withCredentials: true,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });

                this.$router.push('/login');
            } catch (error) {
                console.error('Error during logout:', error);
            }
        },
        reloadPage() {
            this.$emit('reload');
        },
    },
};
</script>
