import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import NotFoundView from '../views/404View.vue'
import RotatorRedirect from '../views/RotatorRedirect.vue'
import store from '../store';



const authGuard = async (to, from, next) => {
  await store.dispatch('fetchUser');
  const authenticated = store.getters.isAuthenticated;

  if (authenticated) {
    next();
  } else {
    next('/login');
  }

};

const authPreventionWhenLogin = async (to, from, next) => {
  await store.dispatch('fetchUser');
  const authenticated = store.getters.isAuthenticated;

  if (authenticated) {
    next('/dashboard');
  } else {
    next();
  }

};

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: function () {
          return import('../views/DashboardView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/about',
        name: 'about',
        component: function () {
          return import('../views/AboutView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/rotator',
        name: 'rotator',
        component: function () {
          return import('../views/RotatorView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/customer-service',
        name: 'customer-service',
        component: function () {
          return import('../views/CustomerServiceView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/form',
        name: 'form',
        component: function () {
          return import('../views/FormView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/form-builder',
        name: 'form-builder',
        component: function () {
          return import('../views/FormBuilderPage.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/form-data/:form_number',
        name: 'form-data',
        component: function () {
          return import('../views/FormDataView.vue')
        },
        beforeEnter: authGuard,
      },
      {
        path: '/form-builder/:form_number',
        name: 'form-builder-edit',
        component: function () {
          return import('../views/FormBuilderPage.vue');
        },
        beforeEnter: authGuard,
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: authPreventionWhenLogin
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    beforeEnter: authPreventionWhenLogin
  },
  {
    path: '/rotator-redirect/',
    name: 'rotator-redirect',
    component: RotatorRedirect
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router