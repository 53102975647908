<template>
  <section class="content" style="padding-top: 5%;">
    <div class="error-page">
      <h2 class="headline text-warning">404</h2>
      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
        <p>
          We could not find the page you were looking for.
          Meanwhile, you may choose to navigate to one of these pages:
        </p>
        <ul>
          <li><router-link to="/dashboard">Go to Dashboard</router-link></li>
          <li><router-link to="/login">Go to Login</router-link></li>
          <li><router-link to="/register">Go to Register</router-link></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  beforeMount() {
    $('body').removeClass('sidebar-mini').addClass('login-page');
    $('title').html('404 | ' + this.$store.state.app.name);
  }
};
</script>

<style>
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
}

.alert-success,
.alert-danger {
  margin-bottom: 1rem;
}
</style>
