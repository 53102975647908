import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ContentHeader from './components/ContentHeader.vue'
import Content from './components/Content.vue'


const app = createApp(App);

app.config.globalProperties.$formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
};

app.component('content-header', ContentHeader)
app.component('content', Content)

router.afterEach((to, from) => {
    if (to.path !== from.path) {
        store.commit('resetToolBar');
    }
});


app.use(store).use(router).mount('#app')
