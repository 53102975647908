import { createStore } from 'vuex';
import app from '../utils/axiosConfig';


export default createStore({
  state: {
    app: {
      name: 'Smart Rotator',
      searchQuery: '',
      toolBar: [],
      formTools: []
    },
    user: null,
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    setFormTools(state, items) {
      state.formTools = items;
    },
    setUser(state, userData) {
      state.user = userData;
    },
    clearUser(state) {
      state.user = null;
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
    setPageToolBar(state, actions) {
      state.toolBar = actions;
    },
    resetToolBar(state) {
      state.toolBar = [];
    }
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const response = await app.get('/api/user');
        commit('setUser', response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
        commit('clearUser');
      }
    },
    updateSearchQuery({ commit }, query) {
      commit('setSearchQuery', query);
    },
    initToolBar({ commit }, items) {
      commit('setPageToolBar', items);
    },
  },
  modules: {
  },
});
