<template>
  <div id="particles-js" class="login-page particles-container" style="min-height: 540px;">
    <div class="login-box" style="position:fixed">
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="/" class="h1">{{ $store.state.app.name }}</a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">Welcome back!</p>

          <div v-if="successMessage" class="alert alert-success" v-html="successMessage"></div>
          <div v-if="errorMessage" class="alert alert-danger" v-html="errorMessage"></div>

          <form @submit.prevent="loginSubmit">
            <div class="input-group mb-3">
              <input v-model="email" type="email" class="form-control" placeholder="Email"
                :class="{ 'is-invalid': emailError }" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
              <div v-if="emailError" class="invalid-feedback">{{ emailError }}</div>
            </div>

            <div class="input-group mb-3">
              <input v-model="password" :type="showPassword ? 'text' : 'password'" class="form-control"
                placeholder="Password" :class="{ 'is-invalid': passwordError }" />
              <div class="input-group-append">
                <button 
                type="button" 
                class="btn btn-outline-secondary" 
                @click="toggleShowPassword" 
                style="width: 40px; text-align: center; padding: 0;border-color:#d7dce0;"
              >
                <i 
                  :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" 
                  style="font-size: 16px; display: inline-block; width: 30px; text-align: center;"
                ></i>
              </button>
              </div>
              <div v-if="passwordError" class="invalid-feedback">{{ passwordError }}</div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember" v-model="rememberMe" />
                  <label for="remember">Remember Me</label>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <button ref="loginButton" type="submit" class="btn btn-primary btn-block" data-style="slide-right">Sign
                  In</button>
              </div>
            </div>

          </form>
          <p class="mb-0">
            <a href="/forgot-password" class="text-center">Forgot password</a>
          </p>
          <p class="mb-0">
            <a href="/register" class="text-center">Register a new account</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'particles.js/particles';
const particlesJS = window.particlesJS;
import { formSubmitUtil } from '../utils/formSubmitUtil';
// import app from '../utils/axiosConfig';

export default {
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      showPassword: false,
      successMessage: '',
      errorMessage: '',
      emailError: '',
      passwordError: '',
    };
  },
  beforeMount() {
    $('body').removeClass('sidebar-mini').addClass('login-page');
    $('title').html('Login | ' + this.$store.state.app.name);

    const successMessage = localStorage.getItem('successMessage');
    if (successMessage) {
      this.successMessage = successMessage;
      localStorage.removeItem('successMessage');
    }

    this.email = localStorage.getItem('email') || '';
    this.password = localStorage.getItem('password') || '';
  },
  mounted() {
    particlesJS('particles-js', {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: '#20448e',
        },
        shape: {
          type: 'circle',
        },
        opacity: {
          value: 0.5,
        },
        size: {
          value: 3,
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#20448e',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 2,
          direction: 'none',
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'grab',
          },
          onclick: {
            enable: true,
            mode: 'push',
          },
        },
      },
      retina_detect: true,
    });
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = this.email
        ? (emailRegex.test(this.email) ? '' : 'Email is not valid')
        : 'Email is required';
    },
    validatePassword() {
      this.passwordError = this.password ? '' : 'Password is required';
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async loginSubmit() {
      this.validateEmail();
      this.validatePassword();

      if (this.emailError || this.passwordError) {
        return;
      }

      const formData = {
        email: this.email,
        password: this.password,
        remember: this.rememberMe,
      };

      formSubmitUtil(this.$refs.loginButton, '/login', formData, false, 'toastr')
        .then(({ success, data }) => {
          localStorage.removeItem('email');
          localStorage.removeItem('password');

          if (success) {
            window.location.replace('/dashboard');
          } else {
            if (data?.email) {
              this.emailError = data.email[0];
            }
            if (data?.password) {
              this.passwordError = data.password[0];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = 'Something went wrong, please contact administrator.';
        });
    },
  },
};
</script>

<style>
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: #dc3545;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #E9ECEF;
  /* Background for particles */
}
</style>
