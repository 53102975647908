<template>
    <div class="wrapper">
        <!-- Preloader Section -->
        <div class="preloader flex-column justify-content-center align-items-center" style="z-index:9999999">
            <svg class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="60" height="60">
                <circle cx="50" cy="50" r="40" stroke="gray" stroke-width="4" fill="none"/>
                <circle cx="50" cy="50" r="40" stroke="blue" stroke-width="4" fill="none" stroke-dasharray="251.2" stroke-dashoffset="0">
                    <animate attributeName="stroke-dashoffset" values="251.2;0" dur="1.5s" repeatCount="indefinite"/>
                </circle>
            </svg>
        </div>

        <navbar @reload="reloadComponent" :key="navbarKey" />

        <sidebar />

        <div class="content-wrapper">
            <router-view :key="componentKey" />
        </div>

        <control-sidebar />
        <footer-section />
    </div>
</template>

<script>
import Navbar from "./layout-component/Navbar.vue";
import Sidebar from "./layout-component/Sidebar.vue";
import FooterSection from "./layout-component/Footer.vue";
import ControlSidebar from "./layout-component/ControlSidebar.vue";

export default {
    components: {
        Navbar, Sidebar, FooterSection, ControlSidebar
    },
    data() {
        return {
            componentKey: 0,
            navKey: 0
        }
    },
    watch: {
        $route() {
            this.componentKey += 1;
            this.navbarKey += 1;
        },
    },
    methods: {
        reloadComponent() {
            this.componentKey += 1;
        },
    },
    beforeMount() {
        $('body').removeClass('login-page').addClass('sidebar-mini');
        $('title').html('Dashboard | ' + this.$store.state.app.name);
    },
    mounted() {
        const preloader = document.querySelector('.preloader');
        const preloaderImage = document.querySelector('.preloader svg');

        setTimeout(() => {
            preloader.style.height = '0px';
        }, 1300);

        setTimeout(() => {
            preloaderImage.style.opacity = '0';
        }, 1500);

        setTimeout(() => {
            preloader.style.display = 'none';
        }, 2000);
    }
};
</script>
