<template>
    <div id="particles-js" class="register-page particles-container" style="min-height: 464px;">
        <div class="register-box" style="position:fixed;">
            <div class="card card-outline card-primary">
                <div class="card-header text-center">
                    <a href="/" class="h1">{{ $store.state.app.name }}</a>
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Sign up to get started.</p>
                    <form @submit.prevent="registerSubmit">
                        <div class="input-group mb-3">
                            <input v-model="name" class="form-control" placeholder="Name"
                                :class="{ 'is-invalid': nameError }" />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-user"></span>
                                </div>
                            </div>
                            <div v-if="nameError" class="invalid-feedback">{{ nameError }}</div>
                        </div>
                        <div class="input-group mb-3">
                            <input v-model="email" class="form-control" placeholder="Email" type="email"
                                :class="{ 'is-invalid': emailError }" />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>
                            <div v-if="emailError" class="invalid-feedback">{{ emailError }}</div>
                        </div>
                        <div class="input-group mb-3">
                            <input 
                              v-model="password" 
                              :type="showPassword ? 'text' : 'password'" 
                              class="form-control" 
                              placeholder="Password" 
                              :class="{ 'is-invalid': passwordError }" 
                            />
                            <div class="input-group-append">
                              <button 
                                type="button" 
                                class="btn btn-outline-secondary" 
                                @click="toggleShowPassword" 
                                style="width: 40px; text-align: center; padding: 0; border-color: #d7dce0;"
                              >
                                <i 
                                  :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" 
                                  style="font-size: 16px; display: inline-block; width: 30px; text-align: center;"
                                ></i>
                              </button>
                            </div>
                            <div v-if="passwordError" class="invalid-feedback">{{ passwordError }}</div>
                          </div>
                          
                          <div class="input-group mb-3">
                            <input 
                              v-model="password_confirmation" 
                              :type="showPasswordConfirmation ? 'text' : 'password'" 
                              class="form-control" 
                              placeholder="Confirm Password" 
                              :class="{ 'is-invalid': passwordConfirmationError }" 
                            />
                            <div class="input-group-append">
                              <button 
                                type="button" 
                                class="btn btn-outline-secondary" 
                                @click="toggleShowPasswordConfirmation" 
                                style="width: 40px; text-align: center; padding: 0; border-color: #d7dce0;"
                              >
                                <i 
                                  :class="showPasswordConfirmation ? 'fa fa-eye' : 'fa fa-eye-slash'" 
                                  style="font-size: 16px; display: inline-block; width: 30px; text-align: center;"
                                ></i>
                              </button>
                            </div>
                            <div v-if="passwordConfirmationError" class="invalid-feedback">{{ passwordConfirmationError }}</div>
                          </div>
                          
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <div class="icheck-primary">
                                    <input type="checkbox" v-model="terms" @change="validateTerms" id="agreeTerms"
                                        value="agree">
                                    <label for="agreeTerms">
                                        I agree to the <a href="#">terms</a>
                                    </label>
                                    <div v-if="termsError" class="invalid-feedback">{{ termsError }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <button type="submit" class="btn btn-primary btn-block" ref="registerButton"
                                    data-style="slide-right">Register</button>
                            </div>
                        </div>

                    </form>
                    <a href="/login" class="text-center">I already have an account</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'particles.js/particles';
const particlesJS = window.particlesJS;
import { formSubmitUtil } from '../utils/formSubmitUtil';
import app from '../utils/axiosConfig';

export default {
    beforeMount() {
        $('body').removeClass('sidebar-mini').addClass('login-page');
        $('title').html(`Register | ${this.$store.state.app.name}`);
    },
    data() {
        return {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            terms: false,
            showPassword: false,
            showPasswordConfirmation: false,
            nameError: '',
            emailError: '',
            passwordError: '',
            passwordConfirmationError: '',
            termsError: '',
        };
    },
    mounted() {
        particlesJS('particles-js', {
            particles: {
                number: {
                    value: 100,
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                },
                color: {
                    value: '#20448e',
                },
                shape: {
                    type: 'circle',
                },
                opacity: {
                    value: 0.5,
                },
                size: {
                    value: 3,
                },
                line_linked: {
                    enable: true,
                    distance: 150,
                    color: '#20448e',
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    speed: 2,
                    direction: 'none',
                },
            },
            interactivity: {
                detect_on: 'canvas',
                events: {
                    onhover: {
                        enable: true,
                        mode: 'grab',
                    },
                    onclick: {
                        enable: true,
                        mode: 'push',
                    },
                },
            },
            retina_detect: true,
        });
    },
    methods: {
        validateName() {
            this.nameError = this.name ? '' : 'Name is required';
        },
        validateEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.emailError = this.email
                ? (emailRegex.test(this.email) ? '' : 'Email is not valid')
                : 'Email is required';
        },
        validatePassword() {
            this.passwordError = this.password
                ? (this.password.length >= 8 ? '' : 'Password must be at least 8 characters')
                : 'Password is required';
        },
        validatePasswordConfirmation() {
            this.passwordConfirmationError = this.password_confirmation
                ? (this.password_confirmation === this.password ? '' : 'Passwords do not match')
                : 'Password confirmation is required';
        },
        validateTerms() {
            this.termsError = this.terms ? '' : 'You must accept the terms';
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
        toggleShowPasswordConfirmation() {
            this.showPasswordConfirmation = !this.showPasswordConfirmation;
        },
        async registerSubmit() {
            this.validateName();
            this.validateEmail();
            this.validatePassword();
            this.validatePasswordConfirmation();
            this.validateTerms();

            if (this.nameError || this.emailError || this.passwordError || this.passwordConfirmationError || this.termsError) {
                return;
            }

            const apiUrl = this.$store.state.app.api_url;
            const formData = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                terms: this.terms,
            };

            try {
                await app.get('/sanctum/csrf-cookie');

                const { success, data, status } = await formSubmitUtil(
                    this.$refs.registerButton,
                    '/api/register',
                    formData,
                    false,
                    'toastr'
                );

                if (success) {
                    localStorage.setItem('successMessage', 'Registration Successful! You can now log in.');
                    localStorage.setItem('email', this.email);
                    localStorage.setItem('password', this.password);
                    window.location.href = `/login?success=true`;
                } else {
                    if (data) {
                        this.nameError = data.name ? data.name[0] : '';
                        this.emailError = data.email ? data.email[0] : '';
                        this.passwordError = data.password ? data.password[0] : '';
                        this.passwordConfirmationError = data.password_confirmation ? data.password_confirmation[0] : '';
                        this.termsError = data.terms ? data.terms[0] : '';
                    }
                }
            } catch (error) {
                console.error("Error during registration:", error);
            }
        },
    },
};
</script>

<style>
.is-invalid {
    border-color: #dc3545;
}

.invalid-feedback {
    display: block;
    color: #dc3545;
}

.particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #E9ECEF;
    /* Background for particles */
}
</style>
